import React from "react";
import { Container } from "@mui/material";
import HeadingSmall from "../components/HeadingSmall.component";
import Heading from "../components/Heading.component";

const AboutUs = () => {
  return (
    <>
      <table
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        className="tablebg commonWidth top_margin"
      >
        <tbody>
          <tr>
            <td>
              <table
                className="commonWidth"
                border="0"
                align="center"
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <HeadingSmall name={"About Us"} />
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <tbody>
                          <tr>
                            <td width="71%" align="left" valign="top">
                              <table
                                width="100%"
                                border="0"
                                cellSpacing="0"
                                cellPadding="0"
                              >
                                <tbody>
                                  <tr>
                                    {/* <Container maxWidth="lg"> */}
                                    <td>
                                    <p className="maincontent">
                                                <span className="welcome-content">Times Guaranty Limited&nbsp;</span>(Earlier known as Times Guaranty
                                                Financials Limited) incorporated
                                                on 27<sup>th</sup> November 1989, received the
                                                certificate of commencement of
                                                business on 04<sup>th</sup> December 1989 and became active
                                                in February, 1990. The
                                                registered office of the Company
                                                is situated at 5<sup>th</sup> Floor, Times Tower, Kamala Mills
                                                Compound, Senapati Bapat Marg,
                                                Mumbai-Maharashtra - 400013. The
                                                Company is registered with
                                                Reserve Bank of India ("<b>RBI</b>") as
                                                a Non-Banking Financial
                                                Institution without accepting
                                                public deposits and received a
                                                certificate of registration
                                                under Section 45-IA of the
                                                Reserve Bank of India Act, 1934
                                                issued by the RBI. The Company
                                                is primarily engaged in the
                                                business of Investments.
                                              </p>

                                      <p className="maincontent">
                                        <span className="welcome-content">
                                        Times Guaranty Limited&nbsp;</span>is promoted by Bennett, Coleman &
                                        Company Limited (BCCL) who are the
                                        proprietors of the Times of India group
                                        of publications. BCCL has been in the
                                        publishing business for over 170 years
                                        and has come to acquire a reputation for
                                        reporting credible, analysed and
                                        unbiased news. The flagship daily of the
                                        Company, The Times of India has been
                                        recognised by BBC as one of the six most
                                        influential dailies of the world. The
                                        other well-known publications of the
                                        group are The Economic Times, Navbharat
                                        Times, Maharashtra Times, Femina and
                                        Filmfare.
                                      </p>
                                    </td>
                                    {/* </Container> */}
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td height={"24"}></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default AboutUs;

import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/Home.Page";
import ContactPage from "../pages/ContactUs.Page";
import AboutUs from "../pages/AboutUs.Page";
import BoardOfDirectors from "../pages/BoardOfDirectors.page";
import CodeOfPractices from "../pages/CodeOfPractices.page";
import TransferOfShares from "../pages/ShareholdersInformation.page";
import OtherCompliance from "../components/OtherCompliance.component";
import DisclosuresUnder from "../pages/DisclosuresUnder.page";
import path from "./Path";
import RightSideNavBar from "../components/RightSideNavBar.component";
import Name from "../pages/[Name].page";
import Admin from "../pages/Admin.page";
import ScrollToTop from "../components/ScrollToTop.component";
import PublicAnnouncement from "../pages/OpenOffer.page";

const Routing = () => {
  return (
    <>
     <ScrollToTop />
      <Routes>
        <Route path={path.home} exact element={<HomePage />} />
        <Route path={path.contactUs} exact element={<ContactPage />} />
        <Route path={path.aboutUs} exact element={<AboutUs />} />
        <Route
          path={path.boardOfDirectors}
          exact
          element={<BoardOfDirectors />}
        />
        <Route
          path={path.codeOfPractices}
          exact
          element={<CodeOfPractices />}
        />
        <Route
          path={path.transferOfShares}
          exact
          element={<TransferOfShares />}
        />
        <Route
          path={path.shareHoldersInformation}
          exact
          element={<RightSideNavBar />}
        />
        <Route
          path={path.disclosuresUnder}
          exact
          element={<DisclosuresUnder />}
        />
        <Route
          path={path.otherCompliance}
          exact
          element={<OtherCompliance />}
        />
        <Route
          path={path.openOffer}
          exact
          element={<PublicAnnouncement />}
        />
        {/* <Route path={path.transferOfShares} exact element={<ContactPage />} /> */}
        <Route path={path.financialResult} exact element={<Name />} />
        <Route path={`/:name`} exact element={<Name />} />
        <Route path={path.admin} exact element={<Admin />} />
      </Routes>
    </>
  );
};

export default Routing;

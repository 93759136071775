import React from "react";
import PDFList from "./PDFList.component";
import Heading from "./Heading.component";
import { connect } from "react-redux";

const GuidingPolicies = (props) => {
  const guidingPoliciesdata = props.guidingPoliciesdata;

  return (
    <>
      <table
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        className="tablebg commonWidth top_margin"
      >
        <tbody>
          <tr>
            <td>
              <table
                className="commonWidth"
                border="0"
                align="center"
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <Heading name={" Guiding Polices"} />
                  <tr>
                    <td align="left" valign="top"></td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="90%"
                        border="0"
                        align="left"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tbody>
                          {guidingPoliciesdata &&
                            guidingPoliciesdata.files &&
                            guidingPoliciesdata.files.map((item, index) => {
                              return (
                                <PDFList
                                  name={item.nameToBeDisplayed.name}
                                  link={item.name}
                                  key={index}
                                  location={item.location}
                                />
                              );
                            })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    guidingPoliciesdata: state.LandingReducer.categories["Guiding Polices"],
  };
};

export default connect(mapStateToProps)(GuidingPolicies);

import React from "react";
const CommitteeTable = ({ name, position, index }) => {
  return (
    <tr key={index}>
      <td align="left" valign="top" className="boardofdirectors-names">
        <strong>{name}</strong>
      </td>
      <td align="left" valign="top" className="boardofdirectors-names">
        {position}
      </td>
    </tr>
  );
};
export default CommitteeTable;

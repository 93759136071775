import React, { useEffect, useRef, useState } from "react";
import Login from "../components/Admin/Login.component"
import PDFUploadForm from "../components/Admin/PDFUploadForm.component";
import { getDataFromLocalStorage } from "../utils/localStorage";
import CustomTabNavigation from "../components/Admin/CustomTabNavigation.component";


const Admin = () => {
 const isUserVerified= getDataFromLocalStorage("verified");
  const [verifiedAdmin, setVerifiedAdmin] = useState(isUserVerified?true:false);


  // return <>{verifiedAdmin ? <PDFUploadForm /> : <Login setVerifiedAdmin={setVerifiedAdmin} />}</>;
  return <>{verifiedAdmin ? <CustomTabNavigation /> : <Login setVerifiedAdmin={setVerifiedAdmin} />}</>;
};

export default Admin;

const setItemToLocalStorage = function (name, value) {
  localStorage.setItem(name, value);
};

const getDataFromLocalStorage = function (name) {
  let localData = localStorage.getItem(name);
  if(localData){
  setTimeout(function () {
    localStorage.removeItem(name);
     window.location.reload();
    }, 900000);
    // }, 900000000);
  }
    return localData;
};

module.exports = { getDataFromLocalStorage, setItemToLocalStorage };

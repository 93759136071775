import * as React from "react";
import Welcome from "../components/Welcome.component";


export default function HomePage() {
  return (
    <>
      <Welcome />
    </>
  );
}

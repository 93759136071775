import { Typography } from "@mui/material";
import React from "react";

const HeadingSmall = ({ name }) => {
  return (
    <tr>
      <td>
        <Typography className="inner-header" fontSize={"20px"}>
          {name}
        </Typography>
      </td>
    </tr>
  );
};

export default HeadingSmall;

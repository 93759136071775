import React, { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

const FormSelect = ({
  defaultValue,
  control,
  name,
  label,
  options,
  onChange,
  // setValue,
  // useForm,
  ...rest
}) => {
  const { getValues } = useForm();

  useEffect(() => {
    // Check if the field already has a value
    const currentValue = getValues(name);
    if (currentValue) {
      // If there is a value, call the onChange method
      const selectedData = options.find(
        (option) => option.value === currentValue
      );
      onChange(currentValue, name, selectedData);
    }
  }, [getValues, name, onChange, options]);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ""}
      render={({ field }) => (
        <FormControl fullWidth variant="outlined">
          <InputLabel>{label}</InputLabel>
          <Select
            required
            {...field}
            {...rest}
            value={field.value || ""}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) {
                const selectedData = options.find(
                  (option) => option.value === e.target.value
                );
                onChange(e.target.value, name, selectedData);
              }
            }}
          >
            {options?.map((option) => {
              if (!["NA", "pageLink"].includes(option?.type)) {
                return (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    disabled={option.isDisabled}
                  >
                    {option?.label}
                  </MenuItem>
                );
              }
              //  else {
              //   return (
              //     <MenuItem key={option.value} value={option.value} disabled>
              //       {option.label}
              //     </MenuItem>
              //   );
              // }
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default FormSelect;

// FormInput.js

import React from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const FormInput = ({ control, name, label, defaultValue, onChange, onBlur, onPaste, ...rest }) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue || ""}
    rules={{
      required: true,
      validate: (value) => {
        // Validate the input value
        const hasAlphanumeric = /[a-zA-Z0-9]/.test(value);
        return hasAlphanumeric || "Invalid input. Please include alphanumeric characters.";
      },
    }}
    render={({ field, fieldState }) => (
      <TextField
        {...field}
        required
        onChange={(e) => {
          // Handle input change
          const value = e?.target?.value;
          const sanitizedValue = value?.replace(/[^a-zA-Z0-9().,/_-]+^/s, "");
          field.onChange(sanitizedValue);

          // Check if the button should be enabled
          const hasAlphanumeric = /[a-zA-Z0-9]/.test(sanitizedValue);
          onChange(sanitizedValue); // Call the parent onChange for additional logic
        }}
        // onBlur={onBlur}
        // onPaste={(e) => {
        //   // Handle paste event
        //   const clipboardData = e?.clipboardData || window?.clipboardData;
        //   const pastedData = clipboardData?.getData("text");
        //   const hasAlphanumeric = /[a-zA-Z0-9]/.test(pastedData);
          
        //   // If pasted data does not contain alphanumeric characters, throw an error
        //   if (!hasAlphanumeric) {
        //     field.onChange(""); // Set the input value to an empty string
        //     onChange(""); // Call the parent onChange to handle additional logic
        //     // throw new Error("Invalid input. Please include alphanumeric characters.");
        //   }

        //   // Sanitize and set the pasted data
        //   const sanitizedValue = pastedData?.replace(/[^a-zA-Z0-9().,/_-]+^/s, "");
        //   field.onChange(sanitizedValue);
        //   onChange(sanitizedValue); // Call the parent onChange for additional logic
        // }}
        label={label}
        error={fieldState.invalid}
        helperText={fieldState.error?.message}
        fullWidth
        {...rest}
      />
    )}
  />
);

export default FormInput;

import React from 'react'
import LazyLoad from 'react-lazyload';

const AssetImage = ({src,...props}) => {
    // const assetUrl = process.env.PUBLIC_URL + src;
  return (
    <>
      {/* <LazyLoad height={200} offset={100}> */}
      {/* <LazyLoad  > */}
      <img src={src} {...props} />
    {/* </LazyLoad> */}
    </>
  )
}

export default AssetImage

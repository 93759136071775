import CryptoJS from 'crypto-js';



  function encrypt(data) {
    const plainText = JSON.stringify(data);
    const cipherText = CryptoJS.AES.encrypt(plainText, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    return cipherText;
  }

function decrypt(encryptedData) {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_ENCRYPTION_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    return null; // Handle decryption error gracefully
  }
}

export { encrypt, decrypt };

import React, { useState } from "react";
import { Box, Button, Container, TextField, Typography,
  Snackbar,IconButton, InputAdornment,
  Alert, } from "@mui/material";
  import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import AxiosInstance from "./../../utils/AxiosInstance";
import NavigationLink from './../form/Link.component';
import { setItemToLocalStorage } from "../../utils/localStorage";
import Loader from "../Loader.component";
import { encrypt } from "../../utils/cryptoHelper";

const Login = ({ setVerifiedAdmin }) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm();
  const [isLoading,setIsLoading]=useState(false);

  const [open, setOpen] = useState(false);
 const [showPassword, setShowPassword] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const verifyAdmin = async (data) => {
    try {
      setIsLoading(true);
     const creds ={data:encrypt(data)}
      const getDetails = await AxiosInstance.post("/auth", creds);
      console.log(getDetails,"get details")
      const response=await getDetails.data;
      if (getDetails.status === 200 && response.verified) {
        setItemToLocalStorage("verified",true)
        setVerifiedAdmin(true);
        setIsLoading(false)
      }else{
        setResponseMessage(getDetails?.response?.data?.message)
        setOpen(true)
        setIsLoading(false)
      }
    } catch (error) {
      // console.error(error.message);
      setResponseMessage(error?.message)
      setOpen(true)
      setIsLoading(false);
    }
  };
  const onSubmit = (data) => {
    verifyAdmin(data);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      {open &&
<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={`error`}
            sx={{ width: "100%" }}
          >
            {responseMessage || "Something went wrong" }
          </Alert>
      </Snackbar>
         } 
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        >
        {isLoading && <Loader/>}
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
          >
          <TextField
            margin="normal"
            {...register("email", {
              required: true,
              validate: {
                maxLength: (v) =>
                v.length <= 50 ||
                "The email should have at most 50 characters",
                matchPattern: (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "Email address must be a valid address",
              },
            })}
            required
            fullWidth
            id="email"
            // value={"admin@tgl.com"}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            />
          <TextField
            margin="normal"
            {...register("password", { required: true })}
            required
            fullWidth
            name="password"
            // value={"Admin@tgl#123"}
            label="Password"
            type={showPassword? "text" :"password"}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isDirty || !isValid}
            >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs>
            <NavigationLink to="#" variant="body2">
            Forgot password?
            </NavigationLink>
            </Grid>
            <Grid item>
            <NavigationLink to="#" variant="body2">
            {"Don't have an account? Sign Up"}
            </NavigationLink>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </Container>
          </>
  );
};

export default Login;

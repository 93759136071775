import React, { useEffect, useState } from "react";
import Welcome from "../components/Welcome.component";
import VerticalTabNavigation from "../components/tabNavigation/VerticalTabNavigation.component";
import QuarterlyDataTable from "../components/QuarterlyDataTable.comopnent";
import PDFSectionLodr from "../components/PDFSectionLodr.componnet";
import { connect } from "react-redux";
import AxiosInstance from "../utils/AxiosInstance";
import Loader from "../components/Loader.component";
import { useNavigate } from "react-router-dom";
import path from "../utils/Path";

const Name = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const menuItems = props.menuItems;
  const subMenuItems = props.subMenuItems;
  const dynamicData = menuItems

    .concat(subMenuItems)
    .filter((item) => !["NA", "PDF", "pageLink"].includes(item.type));

  const data = dynamicData.filter((item, index) => {
    const convertedUrl = `/${item.text.replace(/ /g, "-").toLowerCase()}`;
    if (window.location.pathname === convertedUrl) {
      return item;
    }
  });

  const getData = async () => {
    try {
      const getFileDetails = await AxiosInstance.get("/getFile/getPDFList");
      const fileDetails = await getFileDetails.data;
      const allData = fileDetails.data;

      for (const category in allData) {
        props.STORE_DATA({ categories: category, data: allData[category] });
      }
      props.STORE_MENU_ITEM({
        menuItems: fileDetails?.menuItems,
        subMenuItems: fileDetails?.subMenuItems,
      });
      setIsLoading(false);
    } catch (error) {
      // console.error(error.message);
      navigate(path.home);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Check if data is available in Redux, if not, fetch it.
    if (!props.menuItems || !props.subMenuItems || !props.quarterData) {
      setIsLoading(true);
      getData();
    } else {
      setIsLoading(false);
    }
  }, []);

  function renderComponentBasedOnType(data) {
    // debugger
    const quarterlyData = props.quarterData[data?.heading];
    switch (data?.type) {
      case "LOPDF":
        return <PDFSectionLodr name={data.heading} data={quarterlyData} />;
      case "LOPDFWOI":
        return <PDFSectionLodr name={data.heading} noIcon data={quarterlyData} />;
      case "VTN":
        return (
          <VerticalTabNavigation name={data.heading} data={quarterlyData} />
        );
      case "TBQData":
        return <QuarterlyDataTable name={data.heading} data={quarterlyData} />;
      default:
        return <Welcome />;
    }
  }
  return <>{isLoading ? <Loader /> : renderComponentBasedOnType(data[0])}</>;
};

const mapStateToProps = (state) => {
  return {
    menuItems: state.LandingReducer.menuItems,
    subMenuItems: state.LandingReducer.subMenuItems,
    quarterData: state.LandingReducer.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // STORE_DATA: (data) =>
    //   dispatch(STORE_DATA(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Name);

import "./App.css";
import "./Loader.css";
import Routing from "./utils/Routing";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./components/Footer.component";
import Header from "./components/Header.component";
import Banner from "./components/Banner.component";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import AxiosInstance from "./utils/AxiosInstance";
import { STORE_DATA, STORE_MENU_ITEM } from "./Redux/Landing";
import Loader from "./components/Loader.component";
function App(props) {
  const [isLoading, setIsLoading] = useState(false);
  const defaultTheme = createTheme({
    spacing: 8,
  });
  const containerStyle = {
    width: "952px",
    background: "#fff",
    paddingX: "21px",
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const getFileDetails = await AxiosInstance.get("/getFile/getPDFList");

      if (getFileDetails.status === 200) {
        const fileDetails = await getFileDetails.data;
        const allData = fileDetails.data;
// debugger
        for (const category in allData) {
          props.STORE_DATA({ categories: category, data: allData[category] });
        }
        props.STORE_MENU_ITEM({
          menuItems: fileDetails?.menuItems,
          subMenuItems: fileDetails?.subMenuItems,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    // <Router>
    <>
      {isLoading && <Loader />}
      <ThemeProvider theme={defaultTheme}>
        <Container style={containerStyle}>
          <Header />
          <Banner />
          <Routing />
          <Footer />
        </Container>
      </ThemeProvider>
    </>
    // </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    // errorMsg: state.LandingReducer.error?.ErrorMsg || "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    STORE_DATA: (data) => dispatch(STORE_DATA(data)),
    STORE_MENU_ITEM: (menu) => dispatch(STORE_MENU_ITEM(menu)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

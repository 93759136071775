import logger from "redux-logger";
import thunk from "redux-thunk";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { decrypt, encrypt } from "../utils/cryptoHelper";

const persistConfig = {
  key: "root", // This should be a unique key for your app's storage
  storage, // Specify the storage mechanism (e.g., localStorage)
  // transforms: [
  //   {
  //     // Custom transformation for encryption and decryption
  //     in: (state) => {
  //       // Decrypt the data when reading from local storage
  //       return decrypt(state);
  //     },
  //     out: (state) => {
  //       // Encrypt the data before saving it to local storage
  //       return encrypt  (state);
  //     },
  //   },
  // ],
  // serialize: (data) => encrypt(data), // Serialize the state with encryption
  // deserialize: (data) => decrypt(data), // Deserialize the state with decryption

};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer, // Use the persisted reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store); // Create a persistor to manage rehydration

export { store, persistor };

import React, { useState } from "react";
import Heading from "./Heading.component";
import RightSideNavBar from "./RightSideNavBar.component";
import PDFIcon from "./PDFIcon.component";
import OverlayPopup from "./OverLaypPopUp.component";
import NavigationLink from "./form/Link.component";

const PDFSectionLodr = ({ name, noIcon, data }) => {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  const reversedData=data.files.slice().reverse();

// debugger
  const displayData = reversedData?.map((item, index) => (
    <tr key={index}>
      <td align="left" valign="middle">
        <NavigationLink
        to={`${process.env.REACT_APP_BACKEND_BASE_URL}${item.location}/${item.name}`}
          title={item.nameToBeDisplayed.name}
          target="_blank"
        >
          {item.nameToBeDisplayed.name}
        </NavigationLink>
        {data[item.actualName] && (
          <>
            {data[item.actualName].files.map((subItem, subIndex) => (
              <span key={subIndex}>
                <hr />
                <NavigationLink
                to={`${process.env.REACT_APP_BACKEND_BASE_URL}${subItem.location}/${subItem.name}`}
                  title={subItem.nameToBeDisplayed.name}
                  target="_blank"
                  style={{ marginLeft: 30 }}
                >
                  {subItem.nameToBeDisplayed.name}
                </NavigationLink>
              </span>
            ))}
          </>
        )}
      </td>
      <td width="30" align="center" valign="middle">
        <NavigationLink
        to={`${process.env.REACT_APP_BACKEND_BASE_URL}${item.location}/${item.name}`}
          title={item.nameToBeDisplayed.name}
          target="_blank"
        >
          {!noIcon && <PDFIcon />}
        </NavigationLink>
        {data[item.actualName] && (
          
          <>
            {data[item.actualName].files.map((subItem, subIndex) => (
              <span key={subIndex}>
                <hr />
                <NavigationLink
                to={`${process.env.REACT_APP_BACKEND_BASE_URL}${subItem.location}/${subItem.name}`}
                  title={subItem.nameToBeDisplayed.name}
                  target="_blank"
                >
                  {!noIcon && <PDFIcon />}
                </NavigationLink>
              </span>
            ))}
          </>
        )}
      </td>
    </tr>
  ));

  return (
    <>
      <table
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        className="tablebg commonWidth top_margin"
      >
        <tbody>
          <tr>
            <td>
              <table
                className="commonWidth"
                border="0"
                align="center"
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <Heading name={name} />
                  <tr>
                    <td align="left" valign="top">
                      <div className="info-content">
                        <div className="lhs-content">
                          <table
                            width="100%"
                            border="0"
                            align="left"
                            className="report-display"
                            cellPadding="0"
                            cellSpacing="0"
                          >
                            <tbody>{displayData}</tbody>
                          </table>
                        </div>
                        <RightSideNavBar />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <button onClick={openPopup}>Open Popup</button>
      {showPopup && <OverlayPopup show={showPopup} onClose={closePopup} />} */}
    </>
  );
};

export default PDFSectionLodr;

import React from "react";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AppBar } from "@mui/material";
import path from "../utils/Path";
import NavigationLink from "./form/Link.component";

const defaultTheme = createTheme();

const useStyles = makeStyles((theme) => ({
  footer: {
    // backgroundColor: "#222", // Set the background color
    color: "white", // Set text color to white
    padding: "20px 0", // Add some padding
  },
  link: {
    textDecoration: "none", // Remove underline from links
    color: "white", // Set link color to white
    marginRight: "20px", // Add some space between links
  },
  copyright: {
    fontSize: "12px", // Set font size
    fontFamily: "Arial, Helvetica, sans-serif",
  },
}));
function Footer() {
  const classes = useStyles();
  const appBarStyles = {
    backgroundColor: "white", // Set the background color to white
    boxShadow: "none",
    paddingBottom:"20px",
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="static" style={appBarStyles}>
        <footer className={classes.footer}>
          <table
            className="commonWidth"
            border="0"
            align="center"
            cellPadding="0"
            cellSpacing="0"
          >
            <tbody>
              <tr>
                <td className="footerbg commonWidth">
                  <table
                    width="85%"
                    border="0"
                    align="center"
                    cellPadding="0"
                    cellSpacing="0"
                  >
                    <tbody>
                      <tr>
                        <td
                          colSpan="3"
                          align="left"
                          valign="bottom"
                          className="footer-link"
                        >
                          <table
                            width="60%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                          >
                            <tbody>
                              <tr>
                                <td width="8%" align="left" valign="top">
                                  <NavigationLink
                                    to={path.home}
                                    // className={classes.link}
                                  >
                                    HOME
                                  </NavigationLink>
                                </td>
                                <td width="11%" align="left" valign="top">
                                  <NavigationLink
                                    to={path.aboutUs}
                                    // className={classes.link}
                                  >
                                    ABOUT US
                                  </NavigationLink>
                                </td>
                                <td width="11%" align="left" valign="top">
                                  <NavigationLink
                                    to={path.financialResult}
                                    // className={classes.link}
                                  >
                                    INVESTORS
                                  </NavigationLink>
                                </td>
                                <td width="14%" align="left" valign="top">
                                  <NavigationLink
                                    to={path.contactUs}
                                    // className={classes.link}
                                  >
                                    CONTACT US
                                  </NavigationLink>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3" align="left" valign="top">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="38%"
                          align="left"
                          valign="top"
                          className={classes.copyright}
                        >
                          &copy; {new Date().getFullYear()} Times Guaranty
                          Limited
                        </td>
                        <td width="18%" align="left" valign="top">
                          &nbsp;
                        </td>
                        <td
                          width="44%"
                          align="right"
                          valign="top"
                          className="poweredby"
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </footer>
      </AppBar>
    </ThemeProvider>
  );
}

export default Footer;

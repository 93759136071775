import React from "react";
import TabLeftDetails from "./TabLeftDetails.component";
import RightSideNavBar from "../RightSideNavBar.component";

const TabDetails = ({ activeYear, pdfData }) => {
  return (
    <>
      <tr>
        <td align="left" valign="top">
          <div className="info-content">
            <TabLeftDetails activeYear={activeYear} pdfData={pdfData} />
            <RightSideNavBar />
          </div>
        </td>
      </tr>
    </>
  );
};

export default TabDetails;

import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import AssetImage from "./AssetImage.component";
import { useLocation } from "react-router-dom";

const Banner = () => {
  const location = useLocation();

  // Access properties of the location object, such as pathname, search, and hash
  const { pathname, search, hash } = location;
  const data = {
    "about-us": "/companyprofile-banner.webp",
    "board-of-directors": "/boardofdirectors-banner.webp",
    "code-of-practices": "/financial-banner.webp",
    "disclosures-under-regulation-46-of-the-lodr": "/financial-banner.webp",
    "contact-us": "/contactus-banner.webp",
    "shareholders-information": "/shareholder-banner.webp",
    "other-compliance": "/shareholder-banner.webp",
    "notice-of-bm": "/shareholder-banner.webp",
    "financial-results,-on-conclusion-of-the-meeting-of-the-board-of-directors-where-the-financial-results-were-approved":
      "/shareholder-banner.webp",
    "complete-copy-of-annual-report": "/shareholder-banner.webp",
    "notice-of-meeting-of-board-of-directors-where-the-financial-results-shall-be-discussed":
      "/shareholder-banner.webp",
    "financial-results,-on-conclusion-of-the-meeting-of-the-board-of-directors-where-the-financial-results-were-approved":
      "/shareholder-banner.webp",
    "complete-copy-of-annual-report": "/shareholder-banner.webp",
    "share-holding-pattern": "/shareholder-banner.webp",
    "items-published-in-the-newspaper": "/shareholder-banner.webp",
    "secretarial-compliance-report": "/shareholder-banner.webp",
    "disclosure-under-regulation-30-(8)-of-the-lodr":
      "/shareholder-banner.webp",
    "annual-return-as-provided-under-section-92-of-the-companies-act,-2013-and-rules-made-thereunder":
      "/shareholder-banner.webp",
    "voting-results": "/shareholder-banner.webp",
    "compliance-under-regulation-23(9)": "/shareholder-banner.webp",
    "transfer-of-shares": "/shareholder-banner.webp",
    "open-offer": "/financial-banner.webp",
  };
  const [currentBannerImage, setCurrentBannerImage] = useState("");

  useEffect(() => {
    const trimmedPathname = pathname.trim();
    let foundImage = "";
    Object.keys(data).forEach((item) => {
      const convertedUrl = `/${item.replace(/ /g, "-").toLowerCase()}`;
      if (trimmedPathname === convertedUrl) {
        foundImage = data[item];
      }
    });
    // If no matching URL is found, set a default image (homepage banner)
    setCurrentBannerImage(foundImage || "/banner.webp");
  }, [pathname]);
  useEffect(() => {}, [pathname]);
  return (
    <>
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        className="carousal"
        // autoPlay={true} // Enable auto-play
        // interval={5000} // Set the interval to 1000 milliseconds (1 second)
        // infiniteLoop={true}
      >
        <div>
          <AssetImage
            src={
              currentBannerImage
                ? `assets/banner/images${currentBannerImage}`
                : `assets/banner/images/banner.webp`
            }
            alt="banner"
          />
        </div>
        {/* <div>
          <AssetImage src="assets/banner/images/banner1.webp" alt="banner 2" />
        </div>
        <div>
          <AssetImage src="assets/banner/images/banner2.webp" alt="banner 3" />
        </div> */}
      </Carousel>
    </>
  );
};

export default Banner;

const path = {
  home: "/",
  contactUs: "/contact-us",
  aboutUs: "/about-us",
  boardOfDirectors: "/board-of-directors",
  codeOfPractices: "/code-of-practices",
  shareHoldersInformation: "/shareholders-information",
  disclosuresUnder: "/disclosures-under-regulation-46-of-the-lodr",
  otherCompliance: "/other-compliance",
  transferOfShares: "/transfer-of-shares",
  openOffer:"/open-offer",
  financialResult: "/financial-results,-on-conclusion-of-the-meeting-of-the-board-of-directors-where-the-financial-results-were-approved",
  admin: "/admin",
};
export default path;

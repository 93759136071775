import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Divider } from "@mui/material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal({
  section,
  year,
  submenuListValue,
  openModal,
  handleCloseModal,
  handleConfirmModal,
}) {
  // const [openModal, setOpenModal] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const handleClickOpenModal = () => {
  //   setOpenModal(true);
  // };

  // const handleCloseModal = () => {
  //   setOpenModal(false);
  // };

  return (
    <>
      {/* <Button variant="outlined" onClick={handleClickOpenModal}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="alert-dialog-slide-description"
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            maxHeight: "80%", // Adjust max height as needed
            margin: "auto", // Center modal horizontally
            overflow: "auto", // Allow content to scroll if exceeds height
          },
        }}
      >
        <DialogTitle>
          {"Please confirm your action: Replace the existing file?"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {section && submenuListValue !== section && `${section} > `}
            {submenuListValue === section && `${section} `}
            {year && `${year} > `}
            {submenuListValue &&
              submenuListValue !== section &&
              `${submenuListValue} `}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModal}>Ok</Button>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

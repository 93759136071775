import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import AssetImage from "./AssetImage.component";
import path from "../utils/Path";
import NavigationLink from "./form/Link.component";

const useStyles = makeStyles((theme) => ({
  tablebg: {
    // backgroundColor: "#fff", // Set the background color
    maxWidth: "910px",
  },
  header: {
    backgroundColor: "white", // Set the background color for the header
    color: "black", // Set text color to white
    padding: "20px 0", // Add some padding
  },
  welcomeHeader: {
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "18px",
    fontWeight: "normal",
    paddingTop: "8px",
    paddingLeft: "1px",
    color: " #000000",
  },
  welcomeContent: {
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "12px", // Set font size for the welcome content
    lineHeight: "20px", // Set line height for better readability
    color: " #000000",
    lineHeightStep: "20px",
    textAlign: "justify",
  },
  readmore: {
    textAlign: "right", // Align the read more link to the right
    marginTop: "10px", // Add some space above the read more link
    color: " #000000",
  },
}));

function Welcome() {
  const classes = useStyles();

  return (
    <table
      border="0"
      align="center"
      cellPadding="0"
      cellSpacing="0"
      className={"tablebg commonWidth"}
    >
      <tbody>
        <tr>
          <td>
            <Box className={classes.header}>
              <table
                className="commonWidth"
                border="0"
                align="center"
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        border="0"
                        align="left"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              width="670"
                              align="left"
                              valign="top"
                              className={classes.welcomebg}
                            >
                              <table
                                width="100%"
                                border="0"
                                align="center"
                                cellPadding="0"
                                cellSpacing="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="651"
                                      className={classes.welcomeHeader}
                                    >
                                      Welcome to Times Guaranty Limited
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <AssetImage
                                        src="assets/images/spacer.gif"
                                        width="1"
                                        height="15"
                                        alt="spacer gif"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="left" valign="top">
                                      <table
                                        width="97%"
                                        border="0"
                                        align="left"
                                        cellPadding="0"
                                        cellSpacing="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              width="33%"
                                              align="left"
                                              valign="top"
                                            >
                                              <AssetImage
                                                src="assets/images/welcome-image.jpg"
                                                width="198"
                                                height="124"
                                                alt="Welcome"
                                              />
                                            </td>
                                            <td>
                                              <p className="maincontent">
                                              <span className="welcome-content">Times Guaranty Limited&nbsp;</span>(Earlier known as Times Guaranty
                                                Financials Limited) incorporated
                                                on 27<sup>th</sup> November 1989, received the
                                                certificate of commencement of
                                                business on 04<sup>th</sup> December 1989 and became active
                                                in February, 1990. The
                                                registered office of the Company
                                                is situated at 5<sup>th</sup> Floor, Times Tower, Kamala Mills
                                                Compound, Senapati Bapat Marg,
                                                Mumbai-Maharashtra - 400013. The
                                                Company is registered with
                                                Reserve Bank of India ("<b>RBI</b>") as
                                                a Non-Banking Financial
                                                Institution without accepting
                                                public deposits and received a
                                                certificate of registration
                                                under Section 45-IA of the
                                                Reserve Bank of India Act, 1934
                                                issued by the RBI. The Company
                                                is primarily engaged in the
                                                business of Investments.
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              colSpan="2"
                                              className={"readmore"}
                                            >
                                              <NavigationLink
                                                to={path.aboutUs}
                                                title={"about us"}
                                              >
                                                Read More ..
                                              </NavigationLink>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="right" valign="top" height={"10"}></td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Welcome;

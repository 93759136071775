import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Container,
  Typography,
  Button,
  Grid,
  Box,
  Paper,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import CryptoJS from "crypto-js";

import FormInput from "../form/FormInput";
import FormSelect from "../form/FormSelect";
import AxiosInstance from "../../utils/AxiosInstance";
import Loader from "../Loader.component";
import { encrypt } from "../../utils/cryptoHelper";
import PDFIcon from "../PDFIcon.component";
import Modal from "../Modal.component";
const EditedPDFUploadForm = (props) => {
  const { control, handleSubmit, watch, setError, setValue, formState, reset } =
    useForm({
      mode: "onChange", // Enable reactivity on each change
    });
  const navigate = useNavigate();
  const { isDirty, isValid } = formState;
  const [dis46, setDis46] = useState(false);
  const [showYear, setShowYear] = useState(false);
  const [financialInformation, setFinancialInformation] = useState(false);
  const [years, setYears] = useState([]);

  const [section, setSection] = useState("");
  const [currentYear, setSelectedYear] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [file, setFile] = useState({});

  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [pdfValue, setPdfValue] = useState("");
  const [showPdfPreview, setShowPdfPreview] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setIsError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [currentMenuData, setCurrentMenuData] = useState({});
  const [showSubMenuForVTN, setShowSubMenuForVTN] = useState(false);
  const [newSubMenuItems, setNewSubMenuItems] = useState();
  const [currentSelectedType, setCurrentSelectedType] = useState("");
  const [submenuListValue, setSubmenuListValue] = useState("");

  const menuItems = props.menuItems;
  const subMenuItems = props.subMenuItems;
  const quarterlyData = props.quarterData;

  const mapItems = (items) => {
    return items.map((item, index) => {
      const { text, type, ...rest } = item;
      return {
        label: text,
        value: text,
        type: type,
        ...rest,
      };
    });
  };

  const mapYears = (items) => {
    return items.map((item, index) => {
      return {
        label: item,
        value: item,
      };
    });
  };

  const onSubmit = async (data) => {
    try {
      const isWhitespace = /^\s*$/.test(data?.title);
      if (isWhitespace) {
        setResponseMessage("Title cannot be empty or contain only whitespace");
        setIsError(true);
        setOpen(true);
      } else {
        setIsLoading(true);
        const formData = new FormData();
        const fData = {
          title: data.title,
          section: section,
          year: currentYear,
          url: selectedPdfUrl,
          submenuListValue: submenuListValue,
          fileName: file.name,
          pdfValue: pdfValue,
          edit: "editMode",
        };
        formData.append("data", encrypt(fData));
        formData.append("pdfFile", file);
        const postDetails = await AxiosInstance.post(
          "/upload/updatedDetails",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setSubmenuListValue("");
        setSection("");
        setSelectedYear("");
        setSelectedPdfUrl("");
        setPdfValue("");
        setShowSubMenuForVTN("");
        setDis46(false);
        setShowYear(false);
        setFinancialInformation(false);
        setShowPdfPreview(false);

        if (postDetails.status === 201) {
          setResponseMessage(postDetails?.data?.message);
          setOpen(true);
          setIsLoading(false);
          setTimeout(() => {
            navigate(0);
          }, 5000);
        } else {
          // debugger
          setResponseMessage(
            postDetails?.data?.message ||
              postDetails?.response?.data?.error ||
              postDetails?.error?.response?.data?.error ||
              "Something went wrong"
          );
          setIsError(true);
          setOpen(true);
          setIsLoading(false);
        }
        const inputFile = document.getElementById("pdfFile");
        if (inputFile) {
          inputFile.value = "";
        }

        reset();
      }
    } catch (error) {
      // debugger
      setResponseMessage(
        error?.message || error.response?.data?.error || "Something went wrong"
      );
      setSubmenuListValue("");
      setSection("");
      setSelectedYear("");
      setSelectedPdfUrl("");
      setPdfValue("");
      setShowSubMenuForVTN("");
      setDis46(false);
      setShowYear(false);
      setFinancialInformation(false);
      setShowPdfPreview(false);
      setIsError(true);
      setOpen(true);
      reset();
      setIsLoading(false);
    }
  };

  const handleSelectChange = (selectedItem, selectedMenu, selectedData) => {
    setValue(selectedMenu, selectedItem, selectedData);
    if (!["year", "quarter"].includes(selectedMenu)) {
      setCurrentSelectedType(selectedData?.type);
    }
    if (selectedMenu === "mainMenu") {
      setShowYear(false);
      setSubmenuListValue("");
      setSelectedPdfUrl("");
      setPdfValue("");
      setDis46(selectedItem === "Disclosures under Regulation 46 of the LODR");
      if (selectedItem !== "Disclosures under Regulation 46 of the LODR") {
        setSelectedYear("");
        setSelectedQuarter("");
        const dataWithTextType = quarterlyData[selectedItem]?.files?.map(
          (item) => ({
            ...item,
            text: item?.nameToBeDisplayed?.name,
            type: selectedData?.type || "LOPDF",
          })
        );
        setNewSubMenuItems(dataWithTextType);
        setShowSubMenuForVTN(true);
        setValue("menuList", "");
        setValue("submenu", "");
      } else {
        setShowSubMenuForVTN(false);
      }
      setValue("submenuList", "");

      setFinancialInformation(false);

      setSection(selectedData?.heading || selectedItem);
    }

    if (selectedMenu === "menuList") {
      setSubmenuListValue("");
      setNewSubMenuItems("");
      setFinancialInformation(
        selectedItem === "Financial information including:"
      );
      if (selectedItem !== "Financial information including:") {
        setSelectedYear("");
        setSelectedQuarter("");
        if (
          ["LOPDFWOI", "LOPDF"].includes(
            selectedData?.type || currentSelectedType
          )
        ) {
          const dataWithTextType = quarterlyData[
            selectedData?.heading || selectedItem || section
          ]?.files.map((item) => ({
            ...item,
            text: item?.nameToBeDisplayed?.name,
            type: selectedData?.type || "LOPDF",
          }));
          setNewSubMenuItems(dataWithTextType);
        }
        setShowSubMenuForVTN(
          ["LOPDF", "LOPDFWOI"].includes(
            selectedData?.type || currentSelectedType
          )
        );
      }
      if (!["PDF"].includes(selectedData?.type || currentSelectedType)) {
        setSelectedPdfUrl("");
        setPdfValue("");
      }

      setValue("year", "");
      setShowYear(["VTN", "TBQData"].includes(selectedData?.type));
      setSection(selectedData?.heading || selectedItem);
    }

    if (selectedMenu === "submenu") {
      setSubmenuListValue("");
      setValue("submenuList", "");
      if (["LOPDFWOI", "LOPDF"].includes(selectedData?.type)) {
        const dataWithTextType = quarterlyData[
          selectedData?.heading || selectedItem || section
        ]?.files?.map((item) => ({
          ...item,
          text: item?.nameToBeDisplayed?.name,
          type: selectedData?.type || "LOPDF",
          location: item?.location,
        }));
        setNewSubMenuItems(dataWithTextType);
        setShowSubMenuForVTN(true);
      } else {
        setValue("submenuList", "");
        setShowSubMenuForVTN(false);
      }

      setShowYear(["VTN", "TBQData"].includes(selectedData?.type));
      setSection(selectedData?.heading || selectedItem);
    }
    if (selectedMenu === "year") {
      console.log(currentSelectedType, "in year");
      setSelectedYear(selectedItem);
      setValue("submenuList", "");
      const dataWithTextType = quarterlyData[section][
        selectedData?.value || currentYear
      ]?.files?.map((item) => ({
        ...item,
        text: item?.nameToBeDisplayed?.name,
        type: selectedData?.type || currentSelectedType || "",
      }));
      setNewSubMenuItems(dataWithTextType);
      setShowSubMenuForVTN(
        ["VTN", "TBQData", "LOPDF", "LOPDFWOI"].includes(
          currentSelectedType || selectedData?.type
        )
      );
      setShowYear(true);
    }

    if (selectedMenu === "quarter") {
      console.log(currentSelectedType, "in quarter");
      setSelectedQuarter(selectedItem);
      setShowSubMenuForVTN(
        ["TBQData"].includes(currentSelectedType || selectedData?.type)
      );
    }

    if (selectedMenu !== "submenuList") {
      setShowPdfPreview(false);
      setSubmenuListValue("");
    }
    if (selectedMenu === "submenuList") {
      setSubmenuListValue(selectedItem);
      setValue("title", submenuListValue, { shouldDirty: isValid });
      setSelectedPdfUrl(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${selectedData?.location}/${selectedData?.name}`
      );
      setPdfValue(`${selectedData.name}`);
      setShowPdfPreview(true);
    }

    console.log(selectedData, "selected data");
    if (
      !["year", "quarter", "submenuList"].includes(selectedMenu) &&
      ["VTN", "TBQData"].includes(selectedData?.type)
    ) {
      setSubmenuListValue(" ");
      setCurrentSelectedType(selectedData?.type);
      let convertedData = Object.keys(
        quarterlyData[selectedData?.heading] || {}
      ).map((year) => ({
        year,
        quarters: (quarterlyData[selectedData?.heading][year]?.files || []).map(
          (file, index) => ({
            ...file,
            quarter: `Q${index + 1}`,
            displayableName:
              file.nameToBeDisplayed?.name || "Name not available",
            link: file.name,
          })
        ),
      }));
      setCurrentMenuData(convertedData);
      console.log(currentMenuData, "CMD");
      const calculatedYears = convertedData?.map((item) => {
        const [startYear, endYear] = item.year.split("-");
        const newStartYear = parseInt(startYear).toString();
        const newEndYear = parseInt(endYear).toString();
        return `${newStartYear}-${newEndYear}`;
      });
      setYears(calculatedYears);
      setShowYear(true);
    }
    if (["PDF"].includes(selectedData?.type || currentSelectedType)) {
      setSubmenuListValue(selectedItem || selectedData?.label || "");
      setValue("title", selectedItem ? selectedItem : "", {
        shouldDirty: selectedItem ? isValid : isDirty,
      });
      setShowPdfPreview(true);
      const dataWithTextType = quarterlyData["others"]?.files?.filter(
        (item) => item?.nameToBeDisplayed?.name === selectedData.label
      );

      setPdfValue(dataWithTextType[0].name);
      setSelectedPdfUrl(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${dataWithTextType[0].location}/${dataWithTextType[0].name}`
      );
      setSection("others");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsError(false);
    setOpen(false);
  };
  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleConfirmModal = () => {
    setShowPdfPreview(false);
    handleCloseModal();
  };
  return (
    <>
      {isLoading && <Loader />}

      <Typography variant="h4" gutterBottom marginBottom={"20px"}>
        Edit File
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Grid container spacing={2} display={"flex"} alignItems={"center"}>
          <Grid item xs={12}>
            <FormSelect
              name="mainMenu"
              control={control}
              defaultValue="" // Set an empty string
              label={"Main Menu"}
              options={[
                {
                  label: "Disclosures under Regulation 46 of the LODR",
                  value: "Disclosures under Regulation 46 of the LODR",
                },
                {
                  label: "Other Compliance",
                  value: "Other Compliance",
                  type: "LOPDF",
                },
                {
                  label: "Shareholders' Information",
                  value: "Shareholders' Information",
                  type: "LOPDF",
                },
                {
                  label: "Guiding Polices",
                  value: "Guiding Polices",
                  type: "LOPDF",
                },
                {
                  label: "Open Offer",
                  value: "Open Offer",
                  type: "LOPDF",
                },
              ]}
              onChange={handleSelectChange}
            />
          </Grid>

          {dis46 && (
            <Grid item xs={12}>
              <FormSelect
                control={control}
                name="menuList"
                defaultValue=""
                label="Disclosure under Regulation of the LODR List"
                options={mapItems(menuItems)}
                onChange={handleSelectChange}
              />
            </Grid>
          )}
          {financialInformation && (
            <Grid item xs={12} sm={6}>
              <FormSelect
                control={control}
                name="submenu"
                defaultValue=""
                label="Sub Menu"
                options={mapItems(subMenuItems)}
                onChange={handleSelectChange}
              />
            </Grid>
          )}
          {showYear && (
            <>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  name="year"
                  control={control}
                  defaultValue=""
                  label={"Year"}
                  options={mapYears(years)}
                  onChange={handleSelectChange}
                />
              </Grid>
            </>
          )}

          {showSubMenuForVTN && (
            <Grid item xs={12} sm={6}>
              <FormSelect
                control={control}
                name="submenuList"
                defaultValue=""
                label="Sub Menu List"
                options={mapItems(newSubMenuItems || subMenuItems)}
                onChange={handleSelectChange}
              />
            </Grid>
          )}
          {submenuListValue && selectedPdfUrl && (
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
              }}
            >
              <Controller
                name="pdfFile"
                control={control}
                defaultValue={""}
                render={({ field }) =>
                  showPdfPreview ? (
                    <>
                      <div className="nowrap">
                        <a
                          href={selectedPdfUrl}
                          className="editModePdfFileName"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                         <div className="marginBottomForPdfIcon noUnderline">
                          <PDFIcon />
                         </div>
                         <div className="underlineText nowrap">
                         {watch("submenuList") || submenuListValue}
                         </div>
                        </a>
                      </div>
                      <IconButton onClick={handleClickOpenModal} className="noPadding"  >
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        type="file"
                        accept=".pdf"
                        id="pdfFile"
                        required
                        {...field}
                        onChange={async (e) => {
                          if (e.target.files[0]) {
                            const selectedFile = e.target.files[0];
                            let errorMessage = null;
                            setValue("title", submenuListValue, {
                              shouldDirty: isValid,
                            });
                            // Check if the file type is not PDF
                            if (selectedFile?.type !== "application/pdf") {
                              errorMessage = "Only PDF files are allowed";
                            }

                            // Check if the file name contains forbidden characters
                            const forbiddenCharactersPattern = /[?$%#@<>{}[\]]/;
                            if (
                              forbiddenCharactersPattern.test(
                                selectedFile?.name
                              )
                            ) {
                              errorMessage =
                                "File name with these ?$%#@<>{}[] special characters are not allowed";
                            }

                            // Check if the file size exceeds 10 MB
                            if (selectedFile?.size > 10 * 1024 * 1024) {
                              errorMessage =
                                "File size should not exceed 10 MB.";
                            }
                            // Handle error message and reset the input field and state accordingly
                            if (errorMessage) {
                              field.onChange(""); // Set the input value to an empty string
                              setIsError(true);
                              setResponseMessage(
                                errorMessage || "Please select proper file"
                              );
                              setOpen(true);
                              setFile(null);
                            } else {
                              // If everything is valid, update the state variable
                              console.log(selectedFile, "file");
                              field.onChange(e);
                              setFile(selectedFile);
                            }
                          } else {
                            // If no file is chosen, reset the input value and the state variable
                            field.onChange("");
                            setFile(null);
                          }
                        }}
                      />
                      {!file?.name && (
                        <p
                         className="file-upload-guidelines"
                        >
                          Only PDF files are allowed. <br />
                          Maximum file size is 10 MB. <br />
                          File name should not contain special characters such
                          as {"?$%#@<>{}[]"}.
                        </p>
                      )}
                    </div>
                  )
                }
              />
            </Grid>
          )}
          {showSubMenuForVTN && selectedPdfUrl && (
            <Grid item xs={12}>
              <FormInput
                name="title"
                control={control}
                defaultValue={" "} // Set an empty string
                fullWidth
                label="Title"
                variant="outlined"
                value={submenuListValue}
                onChange={(e) => {
                  let value = e;
                  // Remove special characters and allow only one space between words
                  const sanitizedValue = value
                    .replace(/[^a-zA-Z0-9().,/_-]+/, " ")
                    .replace(/\s+/g, " ")
                    .trimStart();

                  // Limit the input to 150 characters
                  value = sanitizedValue.substring(0, 150);
                  const isValid = value.length > 0 && /[a-zA-Z0-9]/.test(value);
                  setSubmenuListValue(value);
                  setValue("title", value, { shouldDirty: isValid });
                }}
                onBlur={(e) => {
                  const value = e?.target?.value;

                  // Remove leading and trailing whitespace
                  const sanitizedValue = value.trim();
                  const isValid = /[a-zA-Z0-9]/.test(sanitizedValue);
                  // setValue("title", sanitizedValue, { shouldDirty: isValid });
                  // setValue("title", sanitizedValue, { shouldDirty: true });
                }}
                required
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isDirty || !isValid}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      {open && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={`${error ? "error" : "success"}`}
            sx={{ width: "100%" }}
          >
            {responseMessage || "Something went wrong"}
          </Alert>
        </Snackbar>
      )}
      {openModal && (
        <Modal
          section={section}
          year={currentYear}
          submenuListValue={submenuListValue}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          handleConfirmModal={handleConfirmModal}
        ></Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menuItems: state.LandingReducer.menuItems,
    subMenuItems: state.LandingReducer.subMenuItems,
    quarterData: state.LandingReducer.categories,
  };
};

export default connect(mapStateToProps)(EditedPDFUploadForm);

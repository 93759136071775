import React from "react";
import Heading from "./Heading.component";
import RightSideNavBar from "./RightSideNavBar.component";
import NavigationLink from "./form/Link.component";

const QuarterlyDataTable = ({ name, data }) => {
  
  const convertedData = Object.keys(data).map((year) => ({
    year,
    quarters: data[year].files.map((file) => ({
      ...file,
      displayableName: file.nameToBeDisplayed.name,
      pdfLink: file.name,
    })),
  }));

  const reversedData = convertedData.reverse();
  return (
    <>
      <table
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        className="tablebg commonWidth top_margin"
      >
     <tbody>
     <tr>
          <td>
            <table
              className="commonWidth"
              border="0"
              align="center"
              cellPadding="0"
              cellSpacing="0"
            >
             <tbody>
             <Heading name={name} />
              <tr>
                <td align="left" valign="top">
                  <div className="info-content">
                    <div className="lhs-content">
                      <table
                        width="100%"
                        border="0"
                        align="left"
                        className="result-display"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tbody>
                          {reversedData.map((yearData) => (
                            <tr key={yearData.year}>
                              <td width="18%" align="left" valign="middle">
                                <strong>{yearData.year}</strong>
                              </td>
                              {[1, 2, 3, 4].map((quarter, index) => (
                                <td
                                  key={
                                    yearData?.quarters[index]?.displayableName||index
                                  }
                                  align="left"
                                  valign="middle"
                                >
                                  <NavigationLink
                                    title={`Download ${yearData?.quarters[index]?.name}`}
                                    to={`${process.env.REACT_APP_BACKEND_BASE_URL}${yearData?.quarters[index]?.location}/${yearData?.quarters[index]?.name}`}
                                    target="_blank"
                                  >
                                    {yearData?.quarters[index]?.displayableName}
                                  </NavigationLink>
                                </td>
                              ))}
                              {/* Fill in remaining <td> elements if necessary */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <RightSideNavBar />
                  </div>
                </td>
              </tr>
             </tbody>
            </table>
          </td>
        </tr>
     </tbody>
      </table>
    </>
  );
};

export default QuarterlyDataTable;

import React from "react";
import PDFIcon from "./PDFIcon.component";
import NavigationLink from "./form/Link.component";

const PDFList = ({ name, link, noIcon, location }) => (
  <tr>
    <td align="left" valign="top" className="annualreport-header">
      <NavigationLink to={`${process.env.REACT_APP_BACKEND_BASE_URL}${location}/${link}`} target="_blank">
      {name}
      </NavigationLink>
    </td>
    {/* <td align="left" valign="top" className="annualreport-header">
      &nbsp;
    </td> */}
    {!noIcon && (
      <td align="right" valign="middle" className="annualreport-header">
        <NavigationLink to={`${process.env.REACT_APP_BACKEND_BASE_URL}${location}/${link}`} target="_blank">
        {/* <NavigationLink to={`http://localhost:3001/${location}/${link}`} target="_blank"> */}
          <PDFIcon />
        </NavigationLink>
      </td>
    )}
  </tr>
);

export default PDFList;

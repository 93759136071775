import React from "react";
import RightSideNavBar from "./RightSideNavBar.component";
import PDFIcon from "./PDFIcon.component";
import Heading from "./Heading.component";
import { connect } from "react-redux";
import NavigationLink from "./form/Link.component";

const OtherCompliance = (props) => {
  const OtherCompliance = props.OtherCompliance;
  return (
    <>
      <table
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        className="tablebg commonWidth top_margin"
      >
        <tbody>
          <tr>
            <td>
              <table
                className="commonWidth"
                border="0"
                align="center"
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <Heading name={"Other Compliance"} />
                  <tr>
                    <td align="left" valign="top">
                      <div className="info-content">
                        <div className="lhs-content">
                          <table
                            width="100%"
                            border="0"
                            align="left"
                            className="report-display"
                            cellPadding="0"
                            cellSpacing="0"
                          >
                            <tbody>
                            {OtherCompliance &&
                              OtherCompliance.files &&
                              OtherCompliance.files.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td align="left" valign="middle">
                                      <NavigationLink
                                        to={`${process.env.REACT_APP_BACKEND_BASE_URL}${item.location}/${item.name}`}
                                        title=""
                                        target="_blank"
                                      >
                                        {item.nameToBeDisplayed.name}
                                      </NavigationLink>
                                    </td>
                                    <td
                                      width="30"
                                      align="center"
                                      valign="middle"
                                    >
                                      <NavigationLink
                                        to={`${process.env.REACT_APP_BACKEND_BASE_URL}${item.location}/${item.name}`}
                                        title=""
                                        target="_blank"
                                      >
                                        <PDFIcon />
                                      </NavigationLink>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <RightSideNavBar />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

// export default OtherCompliance;
const mapStateToProps = (state) => {
  return {
    OtherCompliance: state.LandingReducer.categories["Other Compliance"],
  };
};


export default connect(mapStateToProps)(OtherCompliance);

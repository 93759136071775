import React from "react";
import AssetImage from "./AssetImage.component";

const PDFIcon = ({className}) => {
  return (
    <>
      <AssetImage
        src="assets/images/pdficon2.png"
        alt="PDF ICon"
        width="22"
        height="22"
        border="0"
        className={className}
      />
    </>
  );
};

export default PDFIcon;

import React from "react";
import path from "../utils/Path";
import NavigationLink from "./form/Link.component";
import { useLocation } from "react-router-dom";
import HeadingSmall from "./HeadingSmall.component";

const RightSideNavBar = () => {
  const location = useLocation(); // Get the current location
  const isActive = (pathName) => location.pathname === pathName; // Check if the path matches
  return (
    <>
      {isActive(path.shareHoldersInformation) && (
        <table
          border="0"
          align="center"
          cellPadding="0"
          cellSpacing="0"
          className="tablebg commonWidth"
        >
          <tbody>
            <tr>
              <td>
                <table
                  className="commonWidth"
                  border="0"
                  align="center"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <tbody>
                    <tr>
                      <td align="left" valign="top" height={"15"}></td>
                    </tr>
                    <HeadingSmall name={"Investors"} />
                    <tr>
                      <td align="left" valign="top" height={"20"}></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      <div className={`rhs-content ${isActive(path.shareHoldersInformation)&& "info-content" }`}>
        <ul className="content-list">
          <li>
            <NavigationLink
              to={path.disclosuresUnder}
              className={isActive(path.disclosuresUnder) ? "active" : ""}
            >
              Disclosures under Regulation 46 of the LODR
            </NavigationLink>
          </li>
          <li>
            <NavigationLink
              to={path.otherCompliance}
              className={isActive(path.otherCompliance) ? "active" : ""}
            >
              Other Compliance
            </NavigationLink>
          </li>
          <li>
            <NavigationLink
              to={path.transferOfShares}
              className={isActive(path.transferOfShares) ? "active" : ""}
            >
              Shareholders' Information
            </NavigationLink>
          </li>
          <li>
            <NavigationLink
              to={path.openOffer}
              className={isActive(path.openOffer) ? "active" : ""}
            >
              Open Offer
            </NavigationLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default RightSideNavBar;

  import React from "react";
  import { Link } from "react-router-dom";

  const NavigationLink = ({ children, to, title, target, className, style }) => {
    return (
      <>
        <Link
          to={to}
          // title={title || to}
          target={target}
          className={className}
          style={style}
        >
          {children}
        </Link>
      </>
    );
  };

  export default NavigationLink;

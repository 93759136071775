import axios from "axios";
import { decrypt, encrypt } from "./cryptoHelper";

const AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}api/`,
  timeout: 0,
  headers: {
    common: {
      "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
    },
    Accept: "application/json",
  },
});

AxiosInstance.defaults.headers.common["x-api-key"] =
  process.env.REACT_APP_X_API_KEY;

AxiosInstance?.interceptors?.request?.use(
  (config) => {
    // Encrypt request data
    // if (config?.method === "post" || config?.method === "put") {
    // // debugger  
    // console.log(config.data,"configdata")
    //   config.data = {data:encrypt(config?.data)};
    // }

    return config;
  },
  (error) => {
      // debugger
      if (error?.response?.data) {
        try {
          error.response.data = decrypt(error.response.data);
        } catch (error) {
          // Handle decryption errors if needed
        }
      }
  return error
  }
);

AxiosInstance.interceptors.response.use(
  (response) => {
    // Decrypt response data
    // debugger
    if (response?.data) {
      try {
        response.data = decrypt(response.data);
        if (response.data === null) {
          // Handle decryption failure
        }
      } catch (error) {
        // Handle decryption errors if needed
      }
    }

    return response;
  },
  (error) => {
    // Decrypt error response data
    // debugger
    if (error?.response?.data) {
      try {
        error.response.data = decrypt(error.response.data);
      } catch (error) {
        // Handle decryption errors if needed
      }
    }
return error
    // Handle other error scenarios as needed
    // return Promise.reject(error);
  }
);


export default AxiosInstance;

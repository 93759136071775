import React from "react";
import ShareholdersInformation from "../components/ShareholdersInformation.component";

const TransferOfShares = () => {
  return (
    <>
      <ShareholdersInformation />
    </>
  );
};

export default TransferOfShares;

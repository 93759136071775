import React from "react";
import AppBar from "@mui/material/AppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AssetImage from "./AssetImage.component";
import path from "../utils/Path";
import NavigationLink from "./form/Link.component";
import { useLocation } from "react-router-dom";

const defaultTheme = createTheme();
const appBarStyles = {
  backgroundColor: "white", // Set the background color to white
  boxShadow: "none",
  paddingBottom: "8px",
};
const Header = () => {
  const location = useLocation(); // Get the current location
  const isActive = (pathName) => location.pathname === pathName; // Check if the path matches
 const urls= [
   "notice-of-meeting-of-board-of-directors-where-the-financial-results-shall-be-discussed",
    "financial-results,-on-conclusion-of-the-meeting-of-the-board-of-directors-where-the-financial-results-were-approved",
    "complete-copy-of-annual-report",
    "share-holding-pattern",
    "items-published-in-the-newspaper",
    "secretarial-compliance-report",
    "disclosure-under-regulation-30-(8)-of-the-lodr",
    "annual-return-as-provided-under-section-92-of-the-companies-act,-2013-and-rules-made-thereunder",
    "voting-results",
    "compliance-under-regulation-23(9)",
    "other-compliance",
    "transfer-of-shares",
    "open-offer",
]
const isMatchWithCurrentURl=urls.map((item,index)=>location.pathname===`/${item}`);


  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="static" style={appBarStyles}>
        <table
          className="commonWidth"
          border="0"
          align="center"
          cellPadding="0"
          cellSpacing="0"
        >
          <tbody>
            <tr>
              <td height={"10"}></td>
            </tr>
            <tr>
              <td align="left" valign="top">
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td width="11%">
                        <NavigationLink to={path.home} title={"home"}>
                          <AssetImage
                            src="assets/images/logo-main.png"
                            alt="Times Guaranty Ltd."
                            width="92"
                            height="81"
                            border="0"
                          />
                        </NavigationLink>
                      </td>
                      <td width="89%" align="left" valign="bottom">
                        <NavigationLink to={path.home} title={"home"}>
                          <AssetImage
                            src="assets/images/text-logo.png"
                            alt="Times Guaranty"
                            width="453"
                            height="38"
                            border="0"
                          />
                        </NavigationLink>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <NavigationLink to={path.home}></NavigationLink>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  className="commonWidth"
                  border="0"
                  align="center"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <tbody>
                    <tr>
                      <td>
                        <div className="header-nav">
                          <ul className="menu">
                            <li>
                              <NavigationLink
                                to={path.home}
                                className={isActive(path.home) ? "active" : ""}
                              >
                                Home
                              </NavigationLink>
                            </li>
                            <li>
                              <NavigationLink
                                to={path.aboutUs}
                                className={
                                  isActive(path.aboutUs) ||
                                  isActive(path.boardOfDirectors) ||
                                  isActive(path.codeOfPractices)
                                    ? "active"
                                    : ""
                                }
                              >
                                About Us
                              </NavigationLink>
                              <ul className="sub_menu">
                                <li>
                                  <NavigationLink
                                    to={path.aboutUs}
                                    className={
                                      isActive(path.aboutUs) ? "active" : ""
                                    }
                                  >
                                    Company Profile
                                  </NavigationLink>
                                </li>
                                <li>
                                  <NavigationLink
                                    to={path.boardOfDirectors}
                                    className={
                                      isActive(path.boardOfDirectors)
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Governance Structure
                                  </NavigationLink>
                                </li>
                                <li>
                                  <NavigationLink
                                    to={path.codeOfPractices}
                                    className={
                                      isActive(path.codeOfPractices)
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Guiding Polices
                                  </NavigationLink>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <NavigationLink
                                to={path.shareHoldersInformation}
                                className={
                                  isActive(path.shareHoldersInformation) ||
                                  isActive(path.disclosuresUnder) ||
                                  isActive(path.otherCompliance) ||
                                  isActive(path.transferOfShares) ||
                                  isMatchWithCurrentURl?.includes(true)
                                    ? "active"
                                    : ""
                                }
                              >
                                Investors
                              </NavigationLink>
                              <ul className="sub_menu">
                                <li>
                                  <NavigationLink
                                    to={path.disclosuresUnder}
                                    className={
                                      isActive(path.disclosuresUnder)
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Disclosures under Regulation 46 of the LODR
                                  </NavigationLink>
                                </li>
                                <li>
                                  <NavigationLink
                                    to={path.otherCompliance}
                                    className={
                                      isActive(path.otherCompliance)
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Other Compliance
                                  </NavigationLink>
                                </li>
                                <li>
                                  <NavigationLink
                                    to={path.transferOfShares}
                                    className={
                                      isActive(path.transferOfShares)
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Shareholders' Information
                                  </NavigationLink>
                                </li>
                                <li>
                                  <NavigationLink
                                    to={path.openOffer}
                                    className={
                                      isActive(path.openOffer)
                                        ? "active"
                                        : ""
                                    }
                                  >
                                   Open Offer
                                  </NavigationLink>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <NavigationLink
                                to={path.contactUs}
                                className={
                                  isActive(path.contactUs) ? "active" : ""
                                }
                              >
                                Contact Us
                              </NavigationLink>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;

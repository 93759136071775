import React from "react";
// import styles from "../Loader.css";

const Loader = () => {
  return (
    <>
   
      <div className={"overlay"}>
        <div className={"overlay__inner"}>
          <div className={"overlay__content"}>
          <div className={"spinner"}>
          {/* <div className={"loader"}></div> */}
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
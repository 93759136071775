import React from "react";
import AssetImage from "../components/AssetImage.component";
import NavigationLink from "../components/form/Link.component";
import { connect } from "react-redux";
import Heading from "../components/Heading.component";

const OpenOffer = (props) => {
  const openOffer = props.openOffer;
  // debugger
  return (
    <>
      <table
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        className="tablebg commonWidth top_margin"
      >
        <tbody>
          <tr>
            <td>
              <table
                className="commonWidth"
                border="0"
                align="center"
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <Heading name={"Open Offer"} />
                  <tr>
                    <td align="left" valign="top"></td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="90%"
                        border="0"
                        align="left"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tbody>
                          {/* <li key={index}> */}
                          {openOffer &&
                            openOffer.files &&
                            openOffer.files.map((item, index) => {
                              return(
                                <tr key={index}>
                                <td
                                  align="left"
                                  valign="top"
                                  className="annualreport-header"
                                >
                                  <AssetImage
                                    src="/assets/images/right-arrow.png"
                                    alt=""
                                    width="12"
                                    height="12"
                                    border="0"
                                  />{" "}
                                  <NavigationLink to={`${process.env.REACT_APP_BACKEND_BASE_URL}${item.location}/${item.name}`} target="_blank">
                                    {item?.nameToBeDisplayed?.name}
                                  </NavigationLink>
                                </td>
                              </tr>
                              )
                            })}

                          {/* </li> */}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="right" valign="top">
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => ({
  openOffer:
    state.LandingReducer.categories["Open Offer"],
});

export default connect(mapStateToProps)(OpenOffer);

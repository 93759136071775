import React, { useState } from "react";
import Heading from "../Heading.component";
import TabHeader from "./TabHeader.component";
import TabDetails from "./TabDetails.comopnent";

const VerticalTabNavigation = ({ name, data }) => {

  const convertedData = Object.keys(data).map((year) => {
    if (data[year]?.files?.length > 0) {
    return {
      year,
      quarters: data[year]?.files?.map((file) => {
     
        return {
          ...file,
          displayableName: file.nameToBeDisplayed?.name || "Name not available",
          pdfLink: file.name,
        };
      }),
    };
  }
  }).filter(Boolean);
  const years = convertedData.map((item) => item.year);

  const [activeYear, setActiveYear] = useState(years[years.length -1]);
  // Find the data for the active year using a single iteration
  const activeYearData = convertedData.find((item) => item.year === activeYear);
  const [tabNavigationData, setTabNavigationData] = useState(
    activeYearData ? activeYearData.quarters : []
  );
  const handleYearChange = (year) => {
    setActiveYear(year);
    // Find the data for the active year and update tabNavigationData
    const updatedData = convertedData.find((item) => item.year === year);
    setTabNavigationData(updatedData ? updatedData.quarters : []);
  };

  return (
    <>
      <table
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        className="tablebg commonWidth top_margin"
      >
      <tbody>
      <tr>
          <td>
            <table
              className="commonWidth"
              border="0"
              align="center"
              cellPadding="0"
              cellSpacing="0"
            >
              <tbody>
              <Heading name={name} />
              <TabHeader
                years={years}
                handleYearChange={handleYearChange}
                activeYear={activeYear}
              />
              <tr>
                <td align="left" valign="top">
                  &nbsp;
                </td>
              </tr>
              {/* Render tab content based on activeYear */}
              <TabDetails pdfData={tabNavigationData} activeYear={activeYear} />
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
      </table>
    </>
  );
};

export default VerticalTabNavigation;

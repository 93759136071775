import React from "react";
import GuidingPolicies from "../components/GuidingPolicies.component";

const CodeOfPractices = () => {
  return (
    <>
      <GuidingPolicies />
    </>
  );
};

export default CodeOfPractices;

import React from "react";
import { Link } from "react-router-dom";

const TabHeader = ({ years, handleYearChange, activeYear }) => {
  return (
    <>
      <tr>
        <td align="left" valign="top">
          <div className="tls-tabs">
            <ul className="nav nav-tabs" id="tabs">
              {years.map((year) => (
                <li key={year}>
                  <Link
                    className={`tab-a ${year === activeYear ? "active-a" : ""}`}
                    onClick={() => handleYearChange(year)}
                  >
                    {year}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </td>
      </tr>
    </>
  );
};

export default TabHeader;

import React from "react";
import DisclosuresUnderMainMenu from "../components/DisclosuresUnderMenu.component";
import { connect } from "react-redux";
// import { menuItems, subMenuItems } from "../utils/Menu";

const DisclosuresUnder = (props) => {
  const menuItems = props.menuItems;
  const subMenuItems = props.subMenuItems;

  return (
    <>
      <table
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        className="tablebg commonWidth top_margin"
      >
        <tbody>
          <tr>
            <td>
              <table
                className="commonWidth"
                border="0"
                align="center"
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <tr>
                    <td align="left" valign="top" className="inner-header">
                      Disclosures under Regulation 46 of the LODR
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top"></td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="90%"
                        border="0"
                        align="left"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              className="annualreport-header"
                            >
                              {subMenuItems && menuItems && (
                                <DisclosuresUnderMainMenu
                                  subMenuItems={subMenuItems}
                                  menuItems={menuItems}
                                />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="right" valign="top">
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menuItems: state.LandingReducer.menuItems,
    subMenuItems: state.LandingReducer.subMenuItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // STORE_DATA: (data) =>
    //   dispatch(STORE_DATA(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisclosuresUnder);

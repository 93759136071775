import React from "react";

const Heading = ({ name }) => {
  return (
    <>
      <tr>
        <td align="left" valign="top" className="inner-header">
          {name}
        </td>
      </tr>
      <tr>
        <td align="left" valign="top">
          &nbsp;
        </td>
      </tr>
    </>
  );
};

export default Heading;

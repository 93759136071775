import { createSlice } from "@reduxjs/toolkit";
// import { produce } from "immer";

const initialState = {
  categories: {
    "Guiding Polices": {},
    "Other Compliance": {},
    "Shareholders' Information": {},
    "Notice of BM": {},
    "Financial results": {},
    "Newspaper Advertisement": {},
    "Secretarial compliance report": {},
    "Correspondence to Stock Exchanges": {},
    "Voting results": {},
    "Annual Report": {},
    "Annual Return": {},
    "Compliance Under Regulation 23(9)": {},
    "Share holding Pattern": {},
    "Open Offer": {},
    others: {},
  },
  menuItems: [],
  subMenuItems: [],
  error: {
    isError: false,
    ErrorMsg: "SomeThing Went Wrong",
    showHide: false,
    redirectTo: "",
  },
};
const Landing_slice = createSlice({
  name: "landing",
  initialState: initialState,
  reducers: {
    STORE_DATA: (state, action) => {
      const { categories, data } = action.payload;
      return {
        ...state,
        categories: {
          ...state.categories,
          [categories]: data,
        },
      };
    },
    STORE_MENU_ITEM: (state, action) => {
      const { menuItems, subMenuItems } = action.payload;
      return {
        ...state,
        menuItems,
        subMenuItems,
      };
    },
    SET_ERROR: (state, action) => {
      const { isError, ErrorMsg, showHide, redirectTo } = action.payload;
      return {
        ...state,
        error: {
          isError,
          ErrorMsg,
          showHide,
          redirectTo,
        },
      };
    },
    SHOW_ERROR_MODAL: (state) => {
      return {
        ...state,
        error: {
          ...state.error,
          showHide: true,
        },
      };
    },
    HIDE_ERROR_MODAL: (state, action) => {
      return {
        ...state,
        error: {
          ...state.error,
          showHide: false,
          redirectTo: action.payload.redirect || "",
        },
      };
    },
  },
});

export const {
  SET_ERROR,
  SHOW_ERROR_MODAL,
  HIDE_ERROR_MODAL,
  STORE_DATA,
  STORE_MENU_ITEM,
} = Landing_slice.actions;
export default Landing_slice.reducer;

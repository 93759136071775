import React from "react";
import PDFIcon from "../PDFIcon.component";
import NavigationLink from "../form/Link.component";

const TabLeftDetails = ({ activeYear, pdfData }) => {
  // debugger
  return (
    <>
      <div
        className={`lhs-content tab ${activeYear && "tab-active"} `}
        data-id={activeYear}
        id={activeYear}
      >
        <table
          width="100%"
          border="0"
          align="left"
          className="report-display"
          cellPadding="0"
          cellSpacing="0"
        >
         <tbody>
         {pdfData.map((pdfFile) => (
            <tr key={pdfFile.nameToBeDisplayed.name}>
              <td align="left" valign="middle">
                <NavigationLink
                to={`${process.env.REACT_APP_BACKEND_BASE_URL}${pdfFile.location}/${pdfFile.name}`}
                  title={pdfFile.nameToBeDisplayed.name}
                  target="_blank"
                >
                  {pdfFile.nameToBeDisplayed.name}
                </NavigationLink>
              </td>
              <td width="30" align="center" valign="middle">
                <NavigationLink
                  to={`${process.env.REACT_APP_BACKEND_BASE_URL}${pdfFile.location}/${pdfFile.name}`}
                  title={pdfFile.nameToBeDisplayed.name}
                  target="_blank"
                >
                  <PDFIcon />
                </NavigationLink>
              </td>
            </tr>
          ))}
         </tbody>
        </table>
      </div>
    </>
  );
};

export default TabLeftDetails;

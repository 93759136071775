import React from "react";
import AssetImage from "./AssetImage.component";
import { connect } from "react-redux";
import NavigationLink from "./form/Link.component";
import path from "../utils/Path";

const MenuItem = ({ text, link, noIcon }) => {
  const convertedUrl = text.replace(/ /g, "-").toLowerCase();
  return (
    <li>
      {!noIcon && (
        <AssetImage
          src="/assets/images/right-arrow.png"
          alt=""
          width="12"
          height="12"
          border="0"
        />
      )}
      <NavigationLink to={`/${convertedUrl}`}>{text}</NavigationLink>
    </li>
  );
};
const SubMenu = ({ items }) => (
  <ul>
    {items?.map((item, index) => (
      <MenuItem key={index} text={item.text} link={item.link} noIcon />
    ))}
  </ul>
);

const DisclosuresUnderMainMenu = ({ menuItems, subMenuItems, other }) => {
  return (
    <ul style={{ margin: "0px", padding: "0px", listStyleType: "none" }}>
      {menuItems?.map((item, index) => {
        let convertedUrl = item.text.replace(/ /g, "-").toLowerCase();
        let anotherLink = false;

        if (item.type === "PDF") {
          other.files.forEach((file) => {
            if (item.text === file.nameToBeDisplayed.name) {
              anotherLink = `${process.env.REACT_APP_BACKEND_BASE_URL}${file.location}/${file.name}`;
            }
          });
        } else if (item.type === "pageLink") {
          convertedUrl = `${item.link}`;
        } else if (item.type === "NA") {
          convertedUrl = `disclosures-under-regulation-46-of-the-lodr`;
        }

        return (
          <li key={index}>
            <AssetImage
              src="/assets/images/right-arrow.png"
              alt=""
              width="12"
              height="12"
              border="0"
            />{" "}
            {anotherLink ? (
              <NavigationLink to={anotherLink} target="_blank">
                {item.text}
              </NavigationLink>
            ) : item.text === "Financial information including:" ? (
              <span>{item.text}</span>
            ) : item.type === "NA" ? (
              <span>{item.text}</span>
            ) : (
              <NavigationLink to={`/${convertedUrl}`}>
                {item.text}
              </NavigationLink>
            )}
            {item.text === "Financial information including:" && (
              <SubMenu items={subMenuItems} />
            )}
          </li>
        );
      })}
    </ul>
  );
};

const mapStateToProps = (state) => ({
  menuItems: state.LandingReducer.menuItems,
  subMenuItems: state.LandingReducer.subMenuItems,
  other: state.LandingReducer.categories.others,
});

export default connect(mapStateToProps)(DisclosuresUnderMainMenu);
